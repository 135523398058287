// Import Core
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { navigate, graphql, useStaticQuery, Link } from "gatsby";
import { useLocation } from "@reach/router";
// Import Components
import Button from "../ui/buttons/Button";
import IconInput from "../ui/IconInput";
// Import Assets
import {
  FaCity,
  FaGlobe,
  FaGlobeEurope,
  FaHotel,
  FaMap,
  FaRegMap,
  FaSearch,
  FaTheaterMasks,
  FaTrain,
  FaUmbrellaBeach,
  FaCopy,
} from "react-icons/fa";
import { GiCruiser } from "react-icons/gi";
import { PiCardsBold } from "react-icons/pi";
import { FaBuildingColumns } from "react-icons/fa6";
// Import Utils
import useMobile from "../../utils/useMobile";
import { toSlug } from "../../utils/search/toSlug";
import {
  filterPagesFromSearchValue,
  getFlattenedLocations,
  getFlattenedProducts,
  getFlattenedPages,
} from "../../utils/search/getSearchData";
// Import Context
import { GlobalContext } from "../../GlobalContext";
import { getSlug } from "../../utils/getSlug";
import { getPagesFromIds } from "../../utils/search/getPagesFromIds";

/****************************************
 * TODO:
 * * Add instant search
 * * Add transitions
 * * Add instant search styling
 **************************************** */

const SearchBarStyling = styled.div`
  background-color: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  z-index: 999;
  width: 100vw;
  padding: 0 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;

  border-top: 1px solid var(--neutral-10);
  border-bottom: 1px solid var(--neutral-10);
  box-shadow: 0px -2px 4px -2px rgba(0, 0, 0, 0.06),
    0px -4px 8px -2px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  .title,
  .search-button {
    width: 106px;
    flex: 0 0 106px;
    @media (max-width: 992px) {
      display: none;
    }
  }

  .search-input {
    width: 100%;
    /* .search-icon-button, */
    .clear-btn {
      position: absolute;
      font-size: 12px;
      color: var(--neutral-50);
      z-index: 998;
      height: 24px;
    }
    /* .search-icon-button, */
    .clear-btn {
      left: 10px;
    }
    .clear-btn {
      right: 10px;
      left: unset;
      top: 0;
      cursor: pointer;
    }
    input {
      padding-left: calc(24px + 16px);
      box-shadow: none;
      border-radius: 0px;
      width: 100%;
      min-width: 100%;
      &:focus {
        outline: transparent;
      }
    }

    /* TODO:  Add transition */
    .instant-search-wrapper {
      position: relative;
      @media (max-width: 768px) {
        /* position: absolute;
				left: 0;
				width: 100%; */
        position: fixed;
        left: 0;
        width: 100%;
        bottom: 72px;
        /* height: 275px; */
      }
      .instant-search-results {
        position: absolute;
        bottom: 45px;
        margin: 0 17px;
        /* padding: 10px 24px; */
        min-width: inherit;
        width: calc(100% - 34px);
        max-width: 900px;
        /* max-height: 200px; */
        max-height: 65vh;
        height: fit-content;
        overflow: hidden;
        overflow-y: auto;
        background: var(--white);
        box-shadow: 0px -2px 4px -2px rgba(0, 0, 0, 0.06),
          0px -4px 8px -2px rgba(0, 0, 0, 0.1);

        @media (max-width: 768px) {
          bottom: 0;
          width: 100%;
          padding: 0;
          margin: 0;
          max-height: 300px;
        }
        .search-result-title {
          display: flex;
          padding: 10px 24px;
          align-items: center;
          gap: 12px;
          border-bottom: 1px solid var(--neutral-25);
          .search-result-icon {
            height: 32px;
            width: 32px;
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            background-color: var(--red);
            color: var(--white);
            svg {
              width: 15px;
              height: auto;
            }
          }
          .search-type-title {
            text-transform: uppercase;
            font-family: var(--font-family-heading);
            color: var(--red);
          }
        }
        .search-result {
          max-width: 100%;
          height: fit-content;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          color: var(--black);
          text-decoration: none;

          padding: 16px 24px;
          gap: 12px;
          border-bottom: 1px solid var(--neutral-10);
          &:hover {
            /* color: var(--white);
						background: green; */
          }
        }
      }
    }
  }

  .searchbar {
    display: flex;
    align-items: center;
    margin: auto 40px;
    gap: 40px;
    height: 100%;
    border-top: 1px solid var(--neutral-10);
    border-bottom: 1px solid var(--neutral-10);
    background: var(--white);
    box-shadow: 0px -2px 4px -2px rgba(0, 0, 0, 0.06),
      0px -4px 8px -2px rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
      /* display: none; */
      height: 0;
      flex-direction: column;
      margin: 40px 24px;
      align-items: start;
      gap: 24px;
      transition: all 0.3s ease-in-out;
    }
    @media (max-width: 768px) {
      &.show {
        display: flex;
        height: fit-content;
      }
    }
  }
`;
const renderSpecificTypeResults = (title, array, clearInput) => {
  const iconMap = {
    attraction: <FaBuildingColumns />,
    tailorMadeHoliday: <FaUmbrellaBeach />,
    riverCruise: <GiCruiser />,
    regionPage: <FaRegMap />,
    railJourney: <FaTrain />,
    opera: <FaTheaterMasks />,
    hotelBreak: <FaHotel />,
    culturalTour: <FaMap />,
    countryPage: <FaGlobeEurope />,
    cityPage: <FaCity />,
    pages: <FaCopy />,
    archivePage: <FaCopy />,
  };
  return (
    <>
      <div className="search-result-title">
        <div className="search-result-icon">{iconMap[array[0]._type]}</div>

        <h5 className="search-type-title">{title}</h5>
      </div>
      {/* {array.slice(0, 3).map((item, key) => { */}
      {array.map((item, key) => {
        const itemSlug = getSlug(item);
        return (
          <Link
            to={itemSlug}
            className="search-result"
            key={key}
            onClick={() => clearInput()}
          >
            <p className="medium bold">{item.name ? item.name : item.title}</p>
          </Link>
        );
      })}
    </>
  );
};

const SearchBar = ({ searchValue, setSearchValue }) => {
  const data = useStaticQuery(graphql`
    query SearchQuery {
      attractions: allSanityAttraction {
        nodes {
          ...SanityAttractionFragment
        }
      }
      tailorMadeHolidays: allSanityTailorMadeHoliday {
        nodes {
          ... on SanityTailorMadeHoliday {
            ...SanityTailorMadeHolidayFragment
          }
        }
      }
      riverCruises: allSanityRiverCruise {
        nodes {
          ... on SanityRiverCruise {
            ...SanityRiverCruiseFragment
          }
        }
      }
      regions: allSanityRegionPage {
        nodes {
          ... on SanityRegionPage {
            ...SanityRegionPageFragment
          }
        }
      }
      railJourneys: allSanityRailJourney {
        nodes {
          ... on SanityRailJourney {
            ...SanityRailJourneyFragment
          }
        }
      }
      operas: allSanityOpera {
        nodes {
          ... on SanityOpera {
            ...SanityOperaFragment
          }
        }
      }
      shortBreaks: allSanityHotelBreak {
        nodes {
          ... on SanityHotelBreak {
            ...SanityHotelBreakFragment
          }
        }
      }
      culturalTours: allSanityCulturalTour {
        nodes {
          ... on SanityCulturalTour {
            ...SanityCulturalTourFragment
          }
        }
      }
      countries: allSanityCountryPage {
        nodes {
          ... on SanityCountryPage {
            _id
            _type
            name
            slug {
              current
            }
            isInEurope
            location {
              lat
              lng
            }
            thumbnail {
              asset {
                gatsbyImageData(height: 280, placeholder: BLURRED)
              }
            }
          }
        }
      }
      cities: allSanityCityPage {
        nodes {
          ... on SanityCityPage {
            ...SanityCityPageFragment
          }
        }
      }
      pages: allSanityPages {
        nodes {
          _id
          _type
          name
          slug {
            current
          }
          parentPageSlug
          addParentPagePrefix
        }
      }
      archivePage: allSanityArchivePage {
        nodes {
          _id
          _type
          name
          slug {
            current
          }
          parentPageSlug
          addParentPagePrefix
        }
      }
    }
  `);
  const attractions = data.attractions.nodes;
  const countries = data.countries.nodes;
  const regions = data.regions.nodes;
  const cities = data.cities.nodes;
  const tailorMadeHolidays = data.tailorMadeHolidays.nodes;
  const culturalTours = data.culturalTours.nodes;
  const shortBreaks = data.shortBreaks.nodes;
  const operas = data.operas.nodes;
  const railJourneys = data.railJourneys.nodes;
  const riverCruises = data.riverCruises.nodes;

  const pages = [...data.pages.nodes, ...data.archivePage.nodes];

  /* // STATIC QUERY FOR:
   * COUNTRY
   * REGION
   * CITY
   *
   * TAILOR MADE HOLIDAYS
   * CULTURAL TOURS
   * SHORT BREAK
   * OPERA
   * RAIL JOURNEY
   * RIVER CRUISE
   */

  // FLATTEN ALL RESULTS
  const [flattenedAttraction, setFlattenedAttraction] = useState(null);
  const [flattenedCountry, setFlattenedCountry] = useState(null);
  const [flattenedRegion, setFlattenedRegion] = useState(null);
  const [flattenedCity, setFlattenedCity] = useState(null);
  const [flattenedTailorMadeHolidays, setFlattenedTailorMadeHolidays] =
    useState(null);
  const [flattenedCulturalTours, setFlattenedCulturalTours] = useState(null);
  const [flattenedShortBreak, setFlattenedShortBreak] = useState(null);
  const [flattenedOpera, setFlattenedOpera] = useState(null);
  const [flattenedRailJourney, setFlattenedRailJourney] = useState(null);
  const [flattenedRiverCruise, setFlattenedRiverCruise] = useState(null);

  const [flattenedPages, setFlattenedPages] = useState(null);

  const location = useLocation();

  // console.log(
  // 	'🚀 ~ file: CountryPage.js:32 ~ CountryPage ~ locationPageContent:',
  // 	locationPageContent
  // );

  useEffect(() => {
    if (data) {
      setFlattenedAttraction(getFlattenedLocations(attractions));
      setFlattenedCountry(getFlattenedLocations(countries));
      setFlattenedRegion(getFlattenedLocations(regions));
      setFlattenedCity(getFlattenedLocations(cities));
      setFlattenedTailorMadeHolidays(getFlattenedProducts(tailorMadeHolidays));
      setFlattenedCulturalTours(getFlattenedProducts(culturalTours));
      setFlattenedShortBreak(getFlattenedProducts(shortBreaks));
      setFlattenedOpera(getFlattenedProducts(operas));
      setFlattenedRailJourney(getFlattenedProducts(railJourneys));
      setFlattenedRiverCruise(getFlattenedProducts(riverCruises));

      setFlattenedPages(getFlattenedPages(pages));
    }
  }, []);

  const isMobile = useMobile();

  // Store results from input here
  // const [filteredData, setFilteredData] = useState([]);
  const [filteredAttractions, setFilteredAttractions] = useState([]);
  const [filteredCountry, setFilteredCountry] = useState([]);
  const [filteredRegion, setFilteredRegion] = useState([]);
  const [filteredCity, setFilteredCity] = useState([]);
  const [filteredTailorMadeHolidays, setFilteredTailorMadeHolidays] = useState(
    []
  );
  const [filteredCulturalTours, setFilteredCulturalTours] = useState([]);
  const [filteredShortBreak, setFilteredShortBreak] = useState([]);
  const [filteredOpera, setFilteredOpera] = useState([]);
  const [filteredRailJourney, setFilteredRailJourney] = useState([]);
  const [filteredRiverCruise, setFilteredRiverCruise] = useState([]);
  const [filteredPages, setFilteredPages] = useState([]);

  const handleInputOnChange = (e) => {
    setSearchValue(e.target.value);
    let wordEntered = e.target.value;

    const searchInputCountry = filterPagesFromSearchValue(
      flattenedCountry,
      wordEntered
    ).map((item) => item._id);
    const searchInputRegion = filterPagesFromSearchValue(
      flattenedRegion,
      wordEntered
    ).map((item) => item._id);
    const searchInputCity = filterPagesFromSearchValue(
      flattenedCity,
      wordEntered
    ).map((item) => item._id);
    const searchInputTailorMadeHolidays = filterPagesFromSearchValue(
      flattenedTailorMadeHolidays,
      wordEntered
    ).map((item) => item._id);
    const searchInputCulturalTours = filterPagesFromSearchValue(
      flattenedCulturalTours,
      wordEntered
    ).map((item) => item._id);
    const searchInputShortBreak = filterPagesFromSearchValue(
      flattenedShortBreak,
      wordEntered
    ).map((item) => item._id);
    const searchInputOpera = filterPagesFromSearchValue(
      flattenedOpera,
      wordEntered
    ).map((item) => item._id);
    const searchInputRailJourney = filterPagesFromSearchValue(
      flattenedRailJourney,
      wordEntered
    ).map((item) => item._id);
    const searchInputRiverCruise = filterPagesFromSearchValue(
      flattenedRiverCruise,
      wordEntered
    ).map((item) => item._id);
    const searchInputAttractions = filterPagesFromSearchValue(
      flattenedAttraction,
      wordEntered
    ).map((item) => item._id);

    const searchInputPages = filterPagesFromSearchValue(
      flattenedPages,
      wordEntered
    ).map((item) => item._id);

    // Then get full hotel details from id
    const searchCountryResults = getPagesFromIds(countries, searchInputCountry);
    const searchRegionResults = getPagesFromIds(regions, searchInputRegion);
    const searchCityResults = getPagesFromIds(cities, searchInputCity);
    const searchTailorMadeHolidaysResults = getPagesFromIds(
      tailorMadeHolidays,
      searchInputTailorMadeHolidays
    );
    const searchCulturalToursResults = getPagesFromIds(
      culturalTours,
      searchInputCulturalTours
    );
    const searchShortBreakResults = getPagesFromIds(
      shortBreaks,
      searchInputShortBreak
    );
    const searchOperaResults = getPagesFromIds(operas, searchInputOpera);
    const searchRailJourneyResults = getPagesFromIds(
      railJourneys,
      searchInputRailJourney
    );
    const searchRiverCruiseResults = getPagesFromIds(
      riverCruises,
      searchInputRiverCruise
    );
    const searchAttractionsResult = getPagesFromIds(
      attractions,
      searchInputAttractions
    );
    const searchPagesResult = getPagesFromIds(pages, searchInputPages);

    // const pillarSearchResults = getPagesFromIds(
    // 	allPillarPages,
    // 	searchInputPillarResults
    // );
    // const blogSearchResults = getPagesFromIds(
    // 	allBlogPosts,
    // 	searchInputBlogResults
    // );

    // Make sure that the state updates everytime the value is changed

    // Make sure that the state updates everytime the value is changed
    if (wordEntered === "") {
      // setFilteredData([]);
      setFilteredCountry([]);
      setFilteredRegion([]);
      setFilteredCity([]);
      setFilteredTailorMadeHolidays([]);
      setFilteredCulturalTours([]);
      setFilteredShortBreak([]);
      setFilteredOpera([]);
      setFilteredRailJourney([]);
      setFilteredRiverCruise([]);
      setFilteredPages([]);
      setFilteredAttractions([]);
    } else {
      // Filter the data based on the search value
      // console.log(flattenedCountry);
      // setFilteredData(
      // 	searchCountryResults.concat(
      // 		searchRegionResults.concat(
      // 			searchCityResults.concat(
      // 				searchTailorMadeHolidaysResults.concat(
      // 					searchCulturalToursResults.concat(
      // 						searchOperaResults.concat(
      // 							searchRailJourneyResults.concat(
      // 								searchRiverCruiseResults.concat(searchShortBreakResults)
      // 							)
      // 						)
      // 					)
      // 				)
      // 			)
      // 		)
      // 	)
      // )
      setFilteredCountry(searchCountryResults);
      setFilteredRegion(searchRegionResults);
      setFilteredCity(searchCityResults);
      setFilteredTailorMadeHolidays(searchTailorMadeHolidaysResults);
      setFilteredCulturalTours(searchCulturalToursResults);
      setFilteredShortBreak(searchShortBreakResults);
      setFilteredOpera(searchOperaResults);
      setFilteredRailJourney(searchRailJourneyResults);
      setFilteredRiverCruise(searchRiverCruiseResults);
      setFilteredPages(searchPagesResult);
      setFilteredAttractions(searchAttractionsResult);
    }
  };

  const handleInputOnSubmit = () => {
    // console.log(searchValue);
    if (searchValue.length > 0) {
      // Push to array of results that we will pass through to the search-results page
      navigate(
        searchValue
          ? `/search-results?value=${toSlug(searchValue)}`
          : "/search-results",
        {
          state: {
            fromSearch: location.pathname === "/search-results/" ? true : false,
          },
        }
      );

      clearInput();
    }
  };

  // If the X in the input box is checked, clear the input and reset the state
  const clearInput = () => {
    // setFilteredData([]);
    setFilteredCountry([]);
    setFilteredRegion([]);
    setFilteredCity([]);
    setFilteredTailorMadeHolidays([]);
    setFilteredCulturalTours([]);
    setFilteredShortBreak([]);
    setFilteredOpera([]);
    setFilteredRailJourney([]);
    setFilteredRiverCruise([]);
    setFilteredPages([]);
    setFilteredAttractions([]);
    setSearchValue("");
    // TODO: Trigger a click on mobile search button to hide collapsible search
    // if (isMobile) {
    // 	document.getElementById('collapsible-search-btn').click();
    // }
  };

  // console.log({tailorMadeHolidaysPageContent, culturalToursPageContent, shortBreakPageContent, operaPageContent, railJourneyPageContent, riverCruisePageContent});
  // console.log({countryPageContent, regionPageContent, cityPageContent});

  return (
    <SearchBarStyling className="searchbar">
      <h3 className="title">Search</h3>
      <div className="search-input">
        <IconInput
          icon={<FaSearch />}
          type="search"
          placeholder="Search countries, places, hotels..."
          className=""
          value={searchValue}
          onChange={handleInputOnChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleInputOnSubmit();
            }
          }}
        />
        {/* {console.log({filteredData})} */}
        {/* This is the instant search results box on change */}
        <div className="instant-search-wrapper">
          {/* {filteredData.length > 0 && ( */}
          {(filteredCountry.length > 0 ||
            filteredRegion.length > 0 ||
            filteredCity.length > 0 ||
            filteredTailorMadeHolidays.length > 0 ||
            filteredCulturalTours.length > 0 ||
            filteredShortBreak.length > 0 ||
            filteredOpera.length > 0 ||
            filteredPages.length > 0 ||
            filteredAttractions.length > 0 ||
            (filteredRailJourney.length > 0 && filteredRiverCruise)) && (
            // WRAP FLATTENED QUERY IN CONTEXT SO IT CAN BE FOUND ON SEARCH PAGE
            <div className="instant-search-results">
              {/* COUNTRY */}
              {filteredCountry.length > 0 &&
                renderSpecificTypeResults(
                  "Country",
                  filteredCountry,
                  clearInput
                )}
              {/* REGION */}
              {filteredRegion.length > 0 &&
                renderSpecificTypeResults("Region", filteredRegion, clearInput)}
              {/* CITY */}
              {filteredCity.length > 0 &&
                renderSpecificTypeResults(
                  "Destination",
                  filteredCity,
                  clearInput
                )}
              {/* Cultural Highlights */}
              {filteredAttractions.length > 0 &&
                renderSpecificTypeResults(
                  "Cultural Highlights",
                  filteredAttractions,
                  clearInput
                )}
              {/* TAILOR-MADE HOLIDAY */}
              {filteredTailorMadeHolidays.length > 0 &&
                renderSpecificTypeResults(
                  "Tailor-made Holiday",
                  filteredTailorMadeHolidays,
                  clearInput
                )}
              {/* CULTURAL TOUR */}
              {filteredCulturalTours.length > 0 &&
                renderSpecificTypeResults(
                  "Cultural Tours & Music Holidays",
                  filteredCulturalTours,
                  clearInput
                )}
              {/* SHORT BREAK */}
              {filteredShortBreak.length > 0 &&
                renderSpecificTypeResults(
                  "Short Break",
                  filteredShortBreak,
                  clearInput
                )}
              {/* OPERA */}
              {filteredOpera.length > 0 &&
                renderSpecificTypeResults(
                  "Independent Opera Holiday",
                  filteredOpera,
                  clearInput
                )}
              {/* RAIL JOURNEY */}
              {filteredRailJourney.length > 0 &&
                renderSpecificTypeResults(
                  "Rail Journey",
                  filteredRailJourney,
                  clearInput
                )}
              {/* RIVER CRUISE */}
              {filteredRiverCruise.length > 0 &&
                renderSpecificTypeResults(
                  "River Cruise",
                  filteredRiverCruise,
                  clearInput
                )}
              {/* PAGES AND ARCHIVE PAGES */}
              {filteredPages.length > 0 &&
                renderSpecificTypeResults("Pages", filteredPages, clearInput)}
            </div>
          )}
        </div>
      </div>
      <Button
        size="md"
        btnStyle="default"
        value="Search"
        clickHandler={() => handleInputOnSubmit()}
        className="search-button"
      />
    </SearchBarStyling>
  );
};

export default SearchBar;
